var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-scrollbar',{attrs:{"wrap-class":"scrollbar-wrapper"}},[_c('el-menu',{attrs:{"router":"","background-color":"#304156","text-color":"#bfcbd9","active-text-color":"rgb(64, 158, 255)","default-active":_vm.$route.path}},[_c('el-menu-item',{attrs:{"index":"/"}},[_c('i',{staticClass:"el-icon-s-home"}),_vm._v(_vm._s(_vm.$t('sidebar.home'))+" ")]),_c('el-menu-item',{attrs:{"index":_vm.$router.resolve({name: 'stylegen'}).href}},[_c('i',{staticClass:"el-icon-brush"}),_vm._v(_vm._s(_vm.$t('sidebar.stylegen'))+" ")]),_c('el-menu-item',{attrs:{"index":_vm.$router.resolve({name: 'help'}).href}},[_c('i',{staticClass:"el-icon-question"}),_vm._v(_vm._s(_vm.$t('sidebar.help'))+" ")]),_c('a',{attrs:{"href":"https://github.com/xfgryujk/blivechat","target":"_blank"}},[_c('el-menu-item',[_c('i',{staticClass:"el-icon-share"}),_vm._v(_vm._s(_vm.$t('sidebar.projectAddress'))+" ")])],1),_c('a',{attrs:{"href":"http://link.bilibili.com/ctool/vtuber","target":"_blank"}},[_c('el-menu-item',[_c('i',{staticClass:"el-icon-link"}),_vm._v(_vm._s(_vm.$t('sidebar.giftRecordOfficial'))+" ")])],1),_c('el-submenu',{attrs:{"index":"null"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-chat-line-square"}),_vm._v("Language ")]),_vm._l(([
          {locale: 'zh', name: '中文'},
          {locale: 'ja', name: '日本語'},
          {locale: 'en', name: 'English'}
        ]),function(ref){
        var locale = ref.locale;
        var name = ref.name;
return _c('el-menu-item',{key:locale,on:{"click":function($event){return _vm.onSelectLanguage(locale)}}},[_vm._v(_vm._s(name))])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }